<template>
  <div>
    <b-card-header class="pb-1">
      <div>
        <b-media
          class="d-flex align-items-center"
          no-body
        >
          <b-media-aside class="mr-1">
            <b-avatar
              size="64"
              variant="primary"
              :text="startupReport.avatar_text"
            />
          </b-media-aside>
          <b-media-body>
            <h4 class="mb-0">
              {{ startupReport.title }}
            </h4>
          </b-media-body>
        </b-media>
      </div>
      <div>
        <b-badge
          v-for="(focus, key) in startupReport.marketing_focus"
          :key="key"
          pill
          variant="light-primary"
          class="mr-1"
        >
          {{ focus }}
        </b-badge>
      </div>
    </b-card-header>
    <b-card-body>
      <h5 class="apply-job-title">
        {{ startupReport.description }}
      </h5>
      <b-card-text class="mb-2">
        {{ startupReport.content }}
      </b-card-text>
      <hr>
      <div class="text-center">
        <b-button
          variant="primary"
          :href="startupReport.website"
          target="_blank"
        >
          <FeatherIcon
            icon="ExternalLinkIcon"
          />
          {{ $t('Website') }}
        </b-button>
        <b-button
          variant="primary"
          class="ml-1 mr-1"
          :href="'mailto:' + startupReport.email"
          target="_blank"
        >
          <FeatherIcon
            icon="MailIcon"
          />
          {{ $t('E-Posta') }}
        </b-button>
        <b-button
          variant="primary"
          :href="startupReport.linkedin"
          target="_blank"
        >
          <FeatherIcon
            icon="LinkedinIcon"
          />
          Linkedin
        </b-button>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import {
  BMedia,
  BCardBody,
  BCardHeader,
  BMediaAside,
  BMediaBody,
  BCardText,
  BAvatar,
  BBadge,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'StartupCard',
  components: {
    BMedia,
    BCardBody,
    BCardHeader,
    BMediaAside,
    BMediaBody,
    BCardText,
    BAvatar,
    BBadge,
    BButton,
  },
  computed: {
    startupReport() {
      return this.$store.getters['startupReport/getStartupReport']
    },
  },
}
</script>
