<template>
  <div>
    <b-card-text>{{ startupReport.problems }}</b-card-text>
  </div>
</template>

<script>
import {
  BCardText,
} from 'bootstrap-vue'

export default {
  name: 'Problems',
  components: {
    BCardText,
  },
  computed: {
    startupReport() {
      return this.$store.getters['startupReport/getStartupReport']
    },
  },
}
</script>

<style scoped>

</style>
