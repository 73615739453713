<template>
  <b-card-body class="d-flex justify-content-between">
    <div class="truncate">
      <h4 class="mb-1 font-weight-bolder">
        {{ $t('Faaliyet Alanları') }}
      </h4>
      <span
        v-for="(activityArea, key) in startupReport.activity_area"
        :key="key"
        class="d-block"
      >
        {{ activityArea }}
      </span>
    </div>
    <b-avatar
      variant="light-info"
      size="45"
    >
      <feather-icon
        size="21"
        icon="ServerIcon"
      />
    </b-avatar>
  </b-card-body>
</template>

<script>
import { BCardBody, BAvatar } from 'bootstrap-vue'

export default {
  name: 'ActivityArea',
  components: {
    BCardBody,
    BAvatar,
  },
  computed: {
    startupReport() {
      return this.$store.getters['startupReport/getStartupReport']
    },
  },
}
</script>

<style scoped>

</style>
