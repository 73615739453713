<template>
  <div>
    <b-card-body>
      <donut-chart
        v-if="startupReport.chartData.seriesData.length > 0"
        :series-data="startupReport.chartData.seriesData"
        :labels-data="startupReport.chartData.labels"
        :colors-data="startupReport.chartData.colors"
      />
    </b-card-body>
  </div>
</template>

<script>
import {
  BCardBody,
} from 'bootstrap-vue'
import donutChart from '@/views/App/Reports/charts/Donut_chart.vue'

export default {
  name: 'DemandInvestment',
  components: {
    BCardBody,
    donutChart,
  },
  computed: {
    startupReport() {
      return this.$store.getters['startupReport/getStartupReport']
    },
  },
}
</script>

<style scoped>

</style>
